import {
	FETCH_SETTINGS,
	FETCH_SETTINGS_SUCCESS,
	FETCH_SETTINGS_FAILURE
} from '../../actions/types';

const defaultState = {
	all: [],
	byId: {},
	isLoading: false,
	error: {}
};

function SettingsReducer(state = defaultState, action) {
	switch (action.type) {

		case FETCH_SETTINGS:
			return {
				...state,
				isLoading: true,
				error: {},
			};

		case FETCH_SETTINGS_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: {},
				all: action.payload,
				// byId: 
			};

		case FETCH_SETTINGS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		default:
			return state;
	}
}

export default SettingsReducer;