export const FETCH_PROCEDURE_DEFS = 'fetch_procedure_definitions';
export const FETCH_PROCEDURE_DEFS_SUCCESS = 'fetch_procedure_defs_success';
export const FETCH_PROCEDURE_DEFS_FAILURE = 'fetch_procedure_defs_failure';

export const ADD_PROCEDURE_TO_REPORT      = 'select_procedure_definitions';
export const REMOVE_PROCEDURE_FROM_REPORT = 'remove_procedure_from_report';

export const FETCH_FILTERS = 'fetch_filters';
export const FETCH_FILTERS_SUCCESS = 'fetch_filters_success';
export const FETCH_FILTERS_FAILURE = 'fetch_filters_failure';

export const SELECT_FILTER = 'select_filter';

export const FETCH_REPORTS              = 'fetch_reports';
export const FETCH_REPORTS_SUCCESS      = 'fetch_reports_success';
export const FETCH_REPORTS_FAILURE      = 'fetch_reports_failure';

export const FETCH_EMPLOYEES         = 'fetch_employees';
export const FETCH_EMPLOYEES_SUCCESS = 'fetch_employees_success';
export const FETCH_EMPLOYEES_FAILURE = 'fetch_employees_failure';

export const FETCH_BODY_LOCATIONS = 'fetch_body_locations';
export const FETCH_BODY_LOCATIONS_SUCCESS = 'fetch_body_locations_success';
export const FETCH_BODY_LOCATIONS_FAILURE = 'fetch_body_locations_failure';

export const FETCH_SETTINGS = 'fetch_settings';
export const FETCH_SETTINGS_SUCCESS = 'fetch_settings_success';
export const FETCH_SETTINGS_FAILURE = 'fetch_settings_failure';

export const OPEN_EDIT_PROCEDURE_DIALOG        = 'open_edit_procedure_dialog';
export const CLOSE_EDIT_PROCEDURE_DIALOG       = 'close_edit_procedure_dialog';
export const UPDATE_EDIT_PROCEDURE_DIALOG_DATA = 'update_edit_procedure_dialog_data';

export const INITIALIZE_REPORT_DATA = 'initialize_report_data';
export const UPDATE_REPORT_DATA     = 'update_report_data';
export const SUBMIT_REPORT          = 'submit_report';
export const SUBMIT_REPORT_ERROR    = 'submit_report_error';

export const LOGIN_DATA_CHANGE = 'login_data_change';
export const LOGIN_REQUEST     = 'login_request';
export const LOGIN_SUCCESS     = 'login_success';
export const LOGIN_ERROR       = 'login_error';
export const LOGOUT_SUCCESS    = 'logout_success';

export const DISMISS_DIALOG = 'dismiss_dialog';
export const RESET_APP      = 'reset_app';