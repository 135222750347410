import {
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGIN_DATA_CHANGE,
	LOGOUT_SUCCESS
} from '../actions/types';

const defaultState = {
	isAuthenticated: false,
	errors: '',
	loginForm: {
		username: '',
		password: '',
	},
	user: {},
};

function AuthenticationReducer(state = defaultState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				error: '',
				loginForm: {
					username: '',
					password: '',
				},
				isAuthenticated: true,
				user: action.payload
			};
		case LOGOUT_SUCCESS:
			return {
				...state,
				user: {},
				isAuthenticated: false
			};
		case LOGIN_ERROR:
			return {
				...state,
				isAuthenticated: false,
				error: action.payload
			};
		case LOGIN_DATA_CHANGE:
			return {
				...state,
				loginForm: {
					...state.loginForm,
					...action.payload
				}
			};
		default:
			return state;
	}
}

export default AuthenticationReducer;