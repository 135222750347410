import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from "react-router-dom";

import { logoutUser } from '../src/scripts/actions/authentication';

const Header = React.lazy(() => import('../src/scripts/components/header'));
const ReportsIndex = React.lazy(() => import('../src/scripts/containers/reports-index'));
const ReportsNew = React.lazy(() => import('../src/scripts/containers/reports-new'));
const PrivateRoute = React.lazy(() => import('../src/scripts/components/hoc/private-route'));
const CustomLoader = React.lazy(() => import('../src/scripts/common/Loader'))

const LogIn = React.lazy(() => import('../src/scripts/containers/authentication/login'))

function App() {
  const { isAuthenticated: isAuth, user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login")
  };

  return (
    <Suspense fallback={<CustomLoader />}>
      <div className="app-container">
        <Header isAuthenticated={isAuth} user={user} logoutFunc={handleLogout} />

        <Routes>
          <Route path="/login" element={<LogIn />} />
          <Route
            path="/"
            element={<PrivateRoute element={ReportsIndex} isAuthenticated={isAuth} />}
          />
          <Route
            path="/reports/new"
            element={<PrivateRoute element={ReportsNew} isAuthenticated={isAuth} />}
          />
        </Routes>
      </div>
    </Suspense>
  );
}
export default App;
