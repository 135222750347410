import {
	FETCH_BODY_LOCATIONS,
	FETCH_BODY_LOCATIONS_SUCCESS,
	FETCH_BODY_LOCATIONS_FAILURE,
} from '../../actions/types';

const defaultState = {
	all: [],
	byId: {},
	isLoading: false,
	error: {},
};

function BodyLocationReducer(state = defaultState, action) {
	switch (action.type) {

		case FETCH_BODY_LOCATIONS:
			return {
				...state,
				isLoading: true,
				error: {},
			};

		case FETCH_BODY_LOCATIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: {},
				all: action.payload,
				byId: action.payload.reduce((hash, obj) => {
					hash[obj.id] = obj;
					return hash;
				}, {})
			};

		case FETCH_BODY_LOCATIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload
			};

		default:
			return state;
	}
}

export default BodyLocationReducer;