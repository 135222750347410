import {combineReducers} from 'redux';

import ProceduresReducer from './entities/procedures';
import FiltersReducer from './entities/procedure-types';
import ReportsReducer from './entities/reports';
import EmployeesReducer from './entities/employees';
import BodyLocationReducer from './entities/body-location';
import SettingsReducer from './entities/settings';

import MessageReducer from './message';
import EditProcedureReducer from './edit-procedure-dialog';
import NewReportReducer from './new-report';
import AuthenticationReducer from './authentication';

const rootReducer = combineReducers({
	reports:       ReportsReducer,
	employees:     EmployeesReducer,
	bodyLocations: BodyLocationReducer,
	procedures:    ProceduresReducer,
	filters:       FiltersReducer,

	message:             MessageReducer,
	editProcedureDialog: EditProcedureReducer,
	newReport:           NewReportReducer,
	auth:                AuthenticationReducer,
	settings: SettingsReducer,
});

export default rootReducer;
