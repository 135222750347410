import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers/index';
import { createLogger } from 'redux-logger';
import reduxThunk from 'redux-thunk';
import {
	LOGIN_SUCCESS
} from './actions/types';

export default function configureStore() {
	const store = createStore(reducers, (applyMiddleware(reduxThunk, createLogger())));
	const token = localStorage.getItem('token');
	const user = JSON.parse(localStorage.getItem('user'));

	if (token && user) {
		store.dispatch({
			type: LOGIN_SUCCESS,
			payload: user
		});
	}

	return store;
}