import {
	FETCH_EMPLOYEES,
	FETCH_EMPLOYEES_SUCCESS,
	FETCH_EMPLOYEES_FAILURE
} from '../../actions/types';

const defaultState = {
	all: [],
	byId: {},
	isLoading: false,
	error: {}
};

function EmployeesReducer(state = defaultState, action) {
	switch (action.type) {

		case FETCH_EMPLOYEES:
			return {
				...state,
				isLoading: true,
				error: {},
			};

		case FETCH_EMPLOYEES_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: {},
				all: action.payload,
				byId: action.payload.reduce((hash, obj) => {
					hash[obj.id] = obj;
					return hash;
				}, {})
			};

		case FETCH_EMPLOYEES_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		default:
			return state;
	}
}

export default EmployeesReducer;