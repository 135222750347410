import config from "../config";
import axios from "axios";


export const login = (options) => {
    return axios.post(`${config.apiBaseUrl}wp-json/jwt-auth/v1/token`, options);
}

export const bodyLocation = () => {
    return getApi(`${config.apiVdV1}body_location`);
}

export const employees = () => {
    return getApi(`${config.apiVdV1}employee`);
}

export const procedures = () => {
    return getApi(`${config.apiVdV1}procedure`);
}

export const reports = () => {
    return getApi(`${config.apiVdV1}report`);
}

export const filters = () => {
    return getApi(`${config.apiVdV1}procedure_type`);
}

export const report = (options) => {
    return axios.post(`${config.apiVdV1}report`, options, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    });
}

export const settings = () => {
    return getApi(`${config.apiVdV1}settings`);
}

function getApi(url) {
    return axios.get(url, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
}
