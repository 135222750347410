import {
	SUBMIT_REPORT,
	SUBMIT_REPORT_ERROR,
	RESET_APP,
	DISMISS_DIALOG,
} from '../actions/types';

const initialState = {
	isOpen: false,
}

function MessageReducer(state = initialState, action) {
	switch (action.type) {
		case RESET_APP:
			return { isOpen: false };
		case SUBMIT_REPORT:
			return {
				type: 'success',
				data: action.payload,
				isOpen: true,
			};
		case SUBMIT_REPORT_ERROR:
			return {
				type: 'error',
				data: action.payload,
				isOpen: true,
			};
		case DISMISS_DIALOG:
			return {
				...state,
				isOpen: false,
			};
		default:
			return state;
	}
}

export default MessageReducer;