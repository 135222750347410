import {
	FETCH_REPORTS,
	FETCH_REPORTS_SUCCESS,
	FETCH_REPORTS_FAILURE,
} from '../../actions/types';

const defaultState = {
	isLoading: false,
	error: {},
	all: [],
};

function ReportsReducer(state = defaultState, action) {
	switch (action.type) {

		case FETCH_REPORTS:
			return {
				...state,
				isLoading: true,
				error: {}
			};

		case FETCH_REPORTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				all: action.payload,
				error: {},
			};

		case FETCH_REPORTS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		default:
			return state;
	}
}

export default ReportsReducer;