import {
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGIN_DATA_CHANGE,
	LOGOUT_SUCCESS,
	LOGIN_REQUEST,
} from './types';

import {login} from "../services";

export const authenticateUser = (obj) => (dispatch) => {

	dispatch({type: LOGIN_REQUEST});

	login(obj).then(res => {
		if(!res.data) {
			return dispatch({
				type: LOGIN_ERROR,
				payload: "Error during authentication! Please try again.",
			});
		}
		localStorage.setItem('token', res.data.token);
		localStorage.setItem('user', JSON.stringify(res.data));
		return dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data,
		});
	}).catch(() => {
		return dispatch({
			type: LOGIN_ERROR,
			payload: "Wrong credentials! Please try again.",
		});
	});
}

export function sendAuthError(error) {
	return {
		type:    LOGIN_ERROR,
		payload: error
	}
}

export function updateLogInFormData(data) {
	return {
		type:    LOGIN_DATA_CHANGE,
		payload: data
	}
}

export function logoutUser() {

	localStorage.removeItem('token');
	localStorage.removeItem('user');

	return {
		type: LOGOUT_SUCCESS
	};
}