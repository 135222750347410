import {
	FETCH_FILTERS,
	FETCH_FILTERS_SUCCESS,
	FETCH_FILTERS_FAILURE,
	SELECT_FILTER,
	RESET_APP
} from '../../actions/types';

const defaultState = {
	all: [],
	byId: {},
	selected: null,
	isLoading: false,
	error: {},
};

function ProcedureTypesReducer(state = defaultState, action) {
	switch (action.type) {

		case RESET_APP:
			return {
				...state,
				selected: null
			};

		case FETCH_FILTERS:
			return {
				...state,
				isLoading: true,
				error: {},
			};

		case FETCH_FILTERS_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: {},
				all: action.payload,
				byId: action.payload.reduce((hash, obj) => {
					hash[obj.id] = obj;
					return hash;
				}, {}),
				selected: null
			};

		case FETCH_FILTERS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		case SELECT_FILTER:
			return {
				...state,
				selected: action.payload
			};
		default:
			return state;
	}
}

export default ProcedureTypesReducer;