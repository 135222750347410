import {
	OPEN_EDIT_PROCEDURE_DIALOG,
	CLOSE_EDIT_PROCEDURE_DIALOG,
	UPDATE_EDIT_PROCEDURE_DIALOG_DATA
} from '../actions/types';

const defaultState = {
	isDialogOpen: false,
	procedure: {},
	procedureDefinition: {},
};

function EditProcedureDialogReducer(state = defaultState, action) {
	switch (action.type) {
		case OPEN_EDIT_PROCEDURE_DIALOG:
			return {
				...state,
				isDialogOpen: true,
				procedure: action.payload.procedure,
				procedureDefinition: action.payload.procedureDefinition
			};
		case UPDATE_EDIT_PROCEDURE_DIALOG_DATA:
			return {
				...state,
				procedure: {
					...state.procedure,
					...action.payload
				}
			}
		case CLOSE_EDIT_PROCEDURE_DIALOG:
			return {
				isDialogOpen: false,
				procedure: {},
				procedureDefinition: {}
			};
		default:
			return state;
	}
}

export default EditProcedureDialogReducer;