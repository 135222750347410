import {
	FETCH_PROCEDURE_DEFS,
	FETCH_PROCEDURE_DEFS_SUCCESS,
	FETCH_PROCEDURE_DEFS_FAILURE,
	ADD_PROCEDURE_TO_REPORT,
	REMOVE_PROCEDURE_FROM_REPORT,
	RESET_APP
} from '../../actions/types';

const defaultState = {
	all: [],
	byId: {},
	selected: [],
	isLoading: false,
	error: {},
};

function ProcedureReducer(state = defaultState, action) {
	switch (action.type) {

		case RESET_APP:
			return {
				...state,
				selected: []
			};

		case FETCH_PROCEDURE_DEFS:
			return {
				...state,
				isLoading: true,
				error: {},
			};

		case FETCH_PROCEDURE_DEFS_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: {},
				all: action.payload,
				byId: action.payload.reduce((hash, obj) => {
					hash[obj.id] = obj;
					return hash;
				}, {})
			};

		case FETCH_PROCEDURE_DEFS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}

		case ADD_PROCEDURE_TO_REPORT:
			return {
				...state,
				selected: state.selected.concat(action.payload)
			};

		case REMOVE_PROCEDURE_FROM_REPORT:
			return {
				...state,
				selected: state.selected.filter((procedureId) => procedureId !== action.payload)
			};

		default:
			return state;
	}
}

export default ProcedureReducer;