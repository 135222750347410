import {
	INITIALIZE_REPORT_DATA,
	UPDATE_REPORT_DATA,
	ADD_PROCEDURE_TO_REPORT,
	REMOVE_PROCEDURE_FROM_REPORT,
	CLOSE_EDIT_PROCEDURE_DIALOG,
	RESET_APP
} from '../actions/types';

import moment from "moment";

const getDefaultState = () => ({
	assignedProcedures: [],
	coordinator: null,
	provider: null,
    dateOfBirth: null,
	date: moment(new Date()).format("YYYY-MM-DD"),
	fullName: '',
	email: '',
	notes: '',
	consultantNotes: '',
	sendEmail: false,
	discount: '',
	discountType: 'dollars',
});

//those have to match API _ (underscored) naming convention as we do not translate them on save
const defaultProcedureState = {
	body_locations: [],
	to_be_performed_by: null,
	price_excluded: false,
	comment: '',
	units: 1,
	scheduling_notes: ""
}

function NewReportReducer(state = getDefaultState(), action) {
	switch (action.type) {
		case INITIALIZE_REPORT_DATA:
			return {
				...state,
			};
		case UPDATE_REPORT_DATA:
			return {
				...state,
				...action.payload
			};
		case ADD_PROCEDURE_TO_REPORT:
			return {
				...state,
				assignedProcedures: state.assignedProcedures.concat({
					...defaultProcedureState,
					procedure_id: action.payload
				})
			};
		case REMOVE_PROCEDURE_FROM_REPORT:
			return {
				...state,
				assignedProcedures: state.assignedProcedures.filter(
					(procedure) => procedure.procedure_id !== action.payload
				)
			}
		case CLOSE_EDIT_PROCEDURE_DIALOG:
			const index = state.assignedProcedures.map((procedure) => procedure.procedure_id).indexOf(action.payload.procedure_id);

			return {
				...state,
				assignedProcedures: state.assignedProcedures.slice(0, index).concat({
					...defaultProcedureState,
					...action.payload
				}).concat(state.assignedProcedures.slice(index + 1))
			};
		case RESET_APP:
			return getDefaultState();
		default:
			return state;
	}
}

export default NewReportReducer
